import React from 'react';
import { FeatureContainer,FeatureWrap, FeatureItems, FeatureH1, FeatureButtonLink } from './FeatureElements';
import Slider from '../Slider'
// import { SliderData } from '../Slider/SliderData';
import Allergy from '../Allergy';
import { Fragment } from 'react/cjs/react.production.min';

function Feature(){
    return (
        <Fragment>
        <FeatureContainer>
            <FeatureWrap>
                <FeatureItems>
                    <FeatureH1>Dive Into Our Menu</FeatureH1>
                    {/* <FeatureP>Delicious Food To Fit Your Lifestyle.</FeatureP> */}
                    {/* <FeatureButton
                        href='https://www.foodbooking.com/ordering/restaurant/menu/checkout/type?restaurant_uid=08c81f72-7234-4d53-a336-ffcc1392d2ba
'
                        target='_blank'
                        aria-label='Redirect to ChowNow USA Online Ordering'
                        rel='noopener noreferrer'
                    >
                        Order Now
                    </FeatureButton> */}
                    <FeatureButtonLink to="/coming-soon" aria-label="Redirect to Coming Soon">Order Now</FeatureButtonLink>
                </FeatureItems>
            </FeatureWrap>
        </FeatureContainer>
        <Slider />
        <Allergy/>
        </Fragment>
    );
};

export default React.memo(Feature);