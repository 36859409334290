import styled from 'styled-components';
import picWall from '../../images/food-3.jpg';
import { Link } from 'react-router-dom';

export const FeatureContainer = styled.div`
    height: 100%;
    background: linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.1)),
    url(${picWall});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover; 

    @media screen and (min-width: 280px){
        height: auto;
    }

    @media screen and (min-width: 312px){
        height: 70vh;
    }

    @media screen and (min-width: 320px){
        height: inherit;
    }

    @media screen and (min-width: 360px){
        height: inherit;
    }

    @media screen and (min-width: 411px){
        height: 80vh;
    }


    @media only screen and (min-width: 568px) and (orientation: landscape) {
        height: inherit;
    }

    @media only screen and (min-width: 640px) and (orientation: landscape) {
        height: inherit;
    }

    @media only screen and (min-width: 1024px) and (orientation: landscape) {
        height: inherit;
        background-size: 100% 125%;

    }
    
`;

export const FeatureWrap = styled.div`
    height: 125vh; 

    @media screen and (max-width: 280px){
        height: 70vh;
    }

    @media screen and (min-width: 320px){
        height: 80vh;
    }

    @media screen and (min-width: 360px){
        height: 80vh;
    }

    @media only screen and (min-width: 568px) and (orientation: landscape) {
        height: 140vh;
    }

    @media only screen and (min-width: 640px) and (orientation: landscape) {
        height: 150vh;
    }

`;

export const FeatureItems = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #fff;
    text-align: center;
    padding: 0 1rem;
    
`;

export const FeatureH1 = styled.h1`
    font-size: clamp(2rem, 10vw, 4rem);
    margin-bottom: 2rem;
    letter-spacing: 1px;
    display: flex;
    flex-direction: center;
    justify-content: center;
    text-align: center;

`;

// export const FeatureP = styled.p`
//     margin-bottom: 2rem;
//     display: flex;
//     flex-direction: center;
//     justify-content: center;
//     text-align: center;
//     font-size: clamp(1.2rem, 4vw, 1.75rem);
// `;

export const FeatureButton = styled.a`
    font-size: clamp(1.2rem, 4vw, 1.75rem);
    padding: 0.5rem 1.5rem;
    border: none;
    border-radius: 5px;
    background: #e31837;
    color: #fff;
    transition: 0.2s ease-out;
    margin-bottom: 2rem;
    text-decoration: none;
    color: white;
    &:hover {
        background: black;
        transition: 0.2s ease-out;
        cursor: pointer;
        color: white;
    }
`;

export const FeatureButtonLink = styled(Link)`
    font-size: clamp(1.2rem, 4vw, 1.75rem);
    padding: 0.5rem 1.5rem;
    border: none;
    border-radius: 5px;
    background: #e31837;
    color: #fff;
    transition: 0.2s ease-out;
    margin-bottom: 2rem;
    text-decoration: none;
    color: white;
    &:hover {
        background: black;
        transition: 0.2s ease-out;
        cursor: pointer;
        color: white;
    }
`;