import styled from 'styled-components';
import {NavLink as Link} from 'react-router-dom';
import {FaBars} from 'react-icons/fa';
import {FaPhone} from 'react-icons/fa';

import woodBackground from '../../images/black-wall-1.jpeg'

export const Nav = styled.nav`


    background:url(${woodBackground});
    height: 30vh;
    position: relative;
    display: flex;
    justify-content: center;
    font-weight: 700;
    border-bottom: 1px solid white;
    background-size: 100% 100%;
    
    @media only screen and (min-width: 812px) and (orientation: landscape) {
        display: grid;
        height: inherit;
    }

    @media only screen and (min-width: 568px) and (orientation: landscape){
        display: grid;
        height: 60vh;
    }

    @media only screen and (min-width: 1024px) and (orientation: landscape){
        display: grid;
        height: 30vh;
    }

    @media only screen and (min-width: 1024px) and (orientation: portrait) {
        display: grid;
        height: 20vh;
    }

`;

export const NavLink = styled(Link)`
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    cursor: pointer;

    @media screen and (max-width: 400px) {
        flex-direction: column;
        
}
`;

export const LogoImg = styled.img`

    color: white;
    width: 165px;
    height: auto;
    
    @media only screen and (min-width: 280px) and (max-width: 767px) {
        max-width: 135px;
        height: auto;
    }

    @media only screen and (min-width: 640px) and (orientation: landscape) {
        
    }
    

`;
export const NavIcon = styled.div`
display: flex;
flex-direction: column;
position: absolute;
top: 10%;
right: 2%;
cursor: pointer;
color: white;

`;

export const Bars = styled(FaBars)`
    font-size: 2rem;
    width: 45px;
    height: 45px;

`;


export const PhoneIcon = styled.div`
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 70%;
    right: 2%;
    cursor: pointer;


`;

export const PhoneIconLink = styled.a`
    color: white;
    &:hover {
        transition: 0.2s ease-out;
        cursor: pointer;
        color: white;
`;

export const Phone = styled(FaPhone)`
    width: 40px;
    height: 40px;


`;