import styled from 'styled-components';
import { Link } from 'react-router-dom';
import woodBackground from '../../images/black-wall-1.jpeg'

// background-color: #0d0909;

export const FooterContainer = styled.footer`
    background:url(${woodBackground});
    background-size: 100% 300%;
    
    @media only screen and (min-width: 320px) and (max-width: 820px) {
        background-attachment: scroll;
        background-position-x: 50%;
        background-position-y: 0%;
        background-position: center top;
        background-repeat: no-repeat;
        background-size: 100% 100%;
    }
    
`;

export const FooterWrap = styled.div`
    padding: 16px 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 1300px;
    margin: 0 auto;
`;

export const SocialMedia = styled.section`
    max-width: 1300px;
    width: 100%;
    color: #fff
    
`;

export const SocialMediaWrap = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1100px;
    letter-spacing: 1px;
    
    margin: 16px auto 0 auto;
    @media screen and (max-width: 820px) {
        flex-direction: column;
    }
`;

export const SocialLogo = styled(Link)`
    color: #fff;
    justify-self: start;
    cursor: pointer;
    text-decoration: none;
    display: flex;
    align-items: center;
    padding: 0.5rem;
`;

export const SocialP = styled.p`
    padding: 0.2rem;
    font-size: clamp(0.75rem, 2vw, 1rem);
    font-family: 'Poppins',sans-serif;
    font-style: normal;
    font-weight: bold;
`;

export const SocialTag = styled.a`

    color: white;
    font-family: 'Poppins',sans-serif;
    text-decoration: none;
    &:hover {
        transition: 0.2s ease-out;
        cursor: pointer;
        color: white;
`;

export const SocialIcons = styled.div`
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 240px;
    @media screen and (max-width: 820px) {
        
    }
`;

export const SocialIconLink = styled.a`
    color: #fff;
    font-size: 32px;

    &:hover {
        transition: 0.2s ease-out;
        cursor: pointer;
        color: white;
`;
