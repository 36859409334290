import React from 'react'
import {FaFacebook, FaInstagram} from 'react-icons/fa';
import { Fragment } from 'react/cjs/react.production.min';
import Location from '../Location ';
import {InformationContainer, InformationWrap, InformationItems, InformationH1, InformationP1, InformationH2, InformationP2, SocialMediaWrap, SocialItems, SocialH1, SocialIcons, SocialIconLink} from './InformationElement'

const Information = () => {
    return (
        <Fragment>
            <InformationContainer>
                <InformationWrap>
                    <InformationItems>
                        <InformationH1>Call Us</InformationH1>
                        <InformationP1>(516) 206-3000</InformationP1>
                        <br></br>
                        <InformationH2>Location</InformationH2>
                        <InformationP2>123 Rockaway Ave, Valley Stream, NY 11580</InformationP2>
                    </InformationItems>
                </InformationWrap>
                <Location/>
                <SocialMediaWrap>
                    <SocialItems>
                        <SocialH1>Follow Us On</SocialH1>
                        <SocialIcons>
                                <SocialIconLink 
                                    href='https://www.facebook.com/SpartansGrill123/'
                                    target='_blank'
                                    aria-label='Redirect to Facebook'
                                    rel='noopener noreferrer'>
                                    <FaFacebook />
                                </SocialIconLink>
                                <SocialIconLink 
                                    href='https://www.instagram.com/spartansgrill/'
                                    target='_blank'
                                    aria-label='Redirect to Instagram'
                                    rel='noopener noreferrer'>
                                    <FaInstagram />
                                </SocialIconLink> 
                            </SocialIcons>
                    </SocialItems>
                </SocialMediaWrap>
            </InformationContainer>
        </Fragment>
    )
}

export default Information;
