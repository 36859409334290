import styled from 'styled-components';
import Pic1 from '../../images/food-8.jpg';
import Pic3 from '../../images/food-4.jpg';
import Pic4 from '../../images/food-1-xxs.png';
import Pic6 from '../../images/food-10.jpg';
import Pic7 from '../../images/food-11.jpg';
import Pic8 from '../../images/food-12.jpg';
import Pic9 from '../../images/food-13.jpg';
import Pic10 from '../../images/food-15.jpg';
import Pic11 from '../../images/food-17.jpg';
import woodBackground from '../../images/black-wall-1.jpeg';

export const Photos = [
    {
        className: 'img',
        alt: 'pic-1',
        image: Pic1,
    },
    {
        className: 'img',
        alt: 'pic-3',
        image: Pic3,
    },
    {
        className: 'img',
        alt: 'pic-4',
        image: Pic4,
    },
    {
        className: 'img',
        alt: 'pic-6',
        image: Pic6,
    },
    {
        className: 'img',
        alt: 'pic-7',
        image: Pic7,
    },
    {
        className: 'img',
        alt: 'pic-8',
        image: Pic8,
    },
    {
        className: 'img',
        alt: 'pic-9',
        image: Pic9,
    },
    {
        className: 'img',
        alt: 'pic-10',
        image: Pic10,
    },
    {
        className: 'img',
        alt: 'pic-11',
        image: Pic11,
    },
];

export const SlideContainer = styled.div`
    position: relative;
    border-bottom: 1px solid;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: url(${woodBackground});
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;

    /* Breakpoints */
    @media screen and (max-width: 280px) {
        height: 60vh;
    }
    @media screen and (max-width: 320px) {
        height: 80vh;
    }
    @media screen and (max-width: 360px) {
        height: 75vh;
    }
    @media screen and (max-width: 375px) {
        height: 70vh;
    }
    @media screen and (max-width: 411px) {
        height: 60vh;
    }
    @media screen and (max-width: 812px) {
        height: 70vh;
    }
    @media screen and (min-width: 1024px) {
        height: 65vh;
    }
    @media only screen and (min-width: 516px) and (orientation: landscape) {
        height: 100vh;
    }
    @media only screen and (min-width: 653px) and (orientation: landscape) {
        height: 125vh;
    }
    @media only screen and (min-width: 1024px) and (orientation: landscape) {
        height: 75vh;
    }
`;
