import styled from 'styled-components';
import { Link } from 'react-router-dom';

// Video Background Container
export const MainContainer = styled.div`
    position: relative;
    height: 100vh;
    width: 100%;
    overflow: hidden;

    /* Ensures video stays within the container */
    video {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        object-fit: cover; /* Ensures video covers container without distortion */
        z-index: -1; /* Pushes video behind content */
    }
`;

// Main Content Wrapper
export const MainContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    height: 100%;
    padding: 0 2rem;
    z-index: 1; /* Brings content above video */

    @media screen and (max-width: 768px) {
        align-items: center;
        text-align: center;
        padding: 0 1rem;
    }
`;

// Text and Button Container
export const MainItems = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    max-width: 650px;
    color: #fff;
    text-transform: uppercase;
    line-height: 1.2;
    font-weight: bold;

    @media screen and (max-width: 768px) {
        align-items: center;
    }
`;

// Main Heading
export const MainH1 = styled.h1`
    font-size: clamp(2rem, 8vw, 5rem);
    margin-bottom: 1rem;
    box-shadow: 3px 5px #e31837;
    letter-spacing: 3px;
`;

// Subheading/Paragraph
export const MainP = styled.p`
    font-size: clamp(1.5rem, 2.5vw, 3rem);
    margin-bottom: 2rem;
`;

// Call-to-Action Button for External Links
export const MainBtn = styled.a`
    font-size: clamp(1.2rem, 4vw, 1.75rem);
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 5px;
    background: #e31837;
    color: #fff;
    transition: 0.2s ease-out;
    text-transform: none;
    text-decoration: none;

    &:hover {
        background: black;
        transition: 0.2s ease-out;
        cursor: pointer;
        color: white;
    }
`;

// Call-to-Action Button for Internal Links using react-router-dom
export const MainBtnLink = styled(Link)`
    font-size: clamp(1.2rem, 4vw, 1.75rem);
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 5px;
    background: #e31837;
    color: #fff;
    transition: 0.2s ease-out;
    text-transform: none;
    text-decoration: none;

    &:hover {
        background: black;
        transition: 0.2s ease-out;
        cursor: pointer;
        color: white;
    }
`;
