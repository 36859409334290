import styled from 'styled-components';
import woodBackground from '../../images/black-wall-1.jpeg'

export const LocationContainer = styled.div`
    background:url(${woodBackground});
    background-size: 100% 100%;
    height: 50vh;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    color: black; 
    border-bottom: 1px solid white;

    @media screen and (max-width: 280px){
        background-size: 100% 100%;
        height: 60vh;
    }

    @media screen and (min-width: 320px){
        background-size: 100% 100%;
        height: 60vh;
    }
    
    @media only screen and (min-width: 568px) and (orientation: landscape) {
        background-size: 100% 100%;
        height: 125vh;
    }

    @media only screen and (min-width: 640px) and (orientation: landscape) {
        background-size: 100% 100%;
        height: 125vh;
    }



    @media only screen and (min-width: 653px) and (orientation: landscape) {
        background-size: 100% 100%;
        height: 125vh;
    }

    @media only screen and (min-width: 720px) and (orientation: landscape) {
        background-size: 100% 100%;
        height: 100vh;
    }

    @media only screen and (min-width: 731px) and (orientation: landscape) {
        background-size: 100% 100%;
        height: 125vh;
    }

    @media only screen and (min-width: 1024px) and (orientation: landscape) {
        background-size: 100% 100%;
        height: 125vh;
    }

`;

export const LocationWrap = styled.div`
    padding: 0.5rem 2rem;
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
`;