import styled from 'styled-components';
import backPic from '../../images/food-7.jpg'
import { Link } from 'react-router-dom';


export const AllergyContainer = styled.div`
    width: auto;
    height: 65vh;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    background: linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.1)),
    url(${backPic});
    background-position: center;
    background-size: cover;

    @media only screen and (max-width: 280px) and (max-width: 820px) {
        background-attachment: scroll;
        background-size: 150% 100%;
        height: 65vh;
    }

    @media only screen and (min-width: 320px) and (max-width: 820px) {
        background-attachment: scroll;
        background-size: 115% 100%;
        height: 55vh;
    }

    @media screen and (min-width: 768px){
        background-attachment: scroll;
        background-size: 100% 100%;
        height: 65vh;
    }


    @media only screen and (min-width: 568px) and (orientation: landscape){
        height: 100vh;
        background-size: 100% 100%;
    }

    @media only screen and (min-width: 640px) and (orientation: landscape){
        height: 100vh;
        background-size: 100% 100%;
    }

    @media only screen and (min-width: 653px) and (orientation: landscape){
        height: 125vh;
        background-size: 100% 120%;
    }

    @media only screen and (min-width: 717px) and (orientation: landscape){
        height: 125vh;
        background-size: 100% 100%;
    }

    @media only screen and (min-width: 731px) and (orientation: landscape){
        height: 100vh;
        background-size: 100% 100%;
    }

    @media only screen and (min-width: 812px) and (orientation: landscape){
        height: 100vh;
        background-size: 100% 125%;
    }

    @media only screen and (min-width: 1024px) and (orientation: landscape){
        height: 100vh;
        background-size: cover;
    }
    
    @media only screen and (min-width: 1024px) and (orientation: portrait){
        height: 50vh;
        background-size: 100% 100%;
    }
`;

export const AllergyWrap = styled.div`
    height: 100%;
    
    
`;
export const AllergyItems = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 1.5rem;
    color: white;
`;
export const AllergyH1 = styled.div`
    display: flex;
    flex-direction: center;
    justify-content: center;
    text-align: center;
    letter-spacing: 1px;
    font-size: clamp(1.5rem, 4vw, 2rem);
    margin-bottom: 1rem;
    text-transform: uppercase;

`;
export const AllergyP = styled.div`
    display: flex;
    flex-direction: center;
    justify-content: center;
    text-align: center;
    font-family: 'Poppins',sans-serif;
    font-size: clamp(0.75rem, 2vw, 1rem);
    font-style: italic;
    margin-bottom: 2rem;
`;

export const AllergyP1 = styled.div`
    display: flex;
    flex-direction: center;
    justify-content: center;
    text-align: center;
    font-family: 'Poppins',sans-serif;
    font-size: clamp(0.75rem, 2vw, 1rem);
    font-style: italic;
    margin-bottom: 2rem;
`;
export const AllergyBtn = styled.a`
    font-size: clamp(1rem, 3vw, 1rem);
    padding: 0.5rem 1.5rem;
    margin-bottom: 1rem;
    border: none;
    background: #e31837;
    color: #fff;
    transition: 0.2s ease-out;
    text-decoration: none;
    color: white;
    &:hover {
        background: white;
        transition: 0.2s ease-out;
        cursor: pointer;
        color: black;
    }
`;

export const AllergyBtnLink = styled(Link)`
    font-size: clamp(1rem, 3vw, 1rem);
    padding: 0.5rem 1.5rem;
    margin-bottom: 1rem;
    border: none;
    background: #e31837;
    color: #fff;
    transition: 0.2s ease-out;
    text-decoration: none;
    color: white;
    &:hover {
        background: white;
        transition: 0.2s ease-out;
        cursor: pointer;
        color: black;
    }
`;