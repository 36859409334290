import React from 'react';
import { MainContainer, MainContent, MainItems, MainH1, MainP, MainBtnLink } from './MainElements';
import { FaFacebook, FaInstagram } from 'react-icons/fa';
import About from '../About';
import Collage from '../Collage';
import Allergy from '../Allergy';
import { Photos } from '../Collage/Photos';
import { SocialMediaWrap, SocialItems, SocialH1, SocialIcons, SocialIconLink } from '../Information/InformationElement';

const Main = () => {
    return (
        <>
            {/* Hero Section */}
            <MainContainer>
                {/* Video Background */}
                <video src="/SpartansGrill.webm" autoPlay loop muted playsInline></video>

                {/* Content */}
                <MainContent>
                    <MainItems>
                        <MainH1>Spartan's Grill</MainH1>
                        <MainP>Modern and Traditional Greek Fast Food Restaurant</MainP>
                        <MainBtnLink to="/coming-soon" aria-label="Redirect to Coming Soon">
                            Order Now
                        </MainBtnLink>
                    </MainItems>
                </MainContent>
            </MainContainer>

            {/* Additional Sections */}
            <About />

            {/* Social Media Section */}
            <SocialMediaWrap>
                <SocialItems>
                    <SocialH1>Follow Us On</SocialH1>
                    <SocialIcons>
                        <SocialIconLink
                            href="https://www.facebook.com/SpartansGrill123/"
                            target="_blank"
                            aria-label="Redirect to Facebook"
                            rel="noopener noreferrer"
                        >
                            <FaFacebook />
                        </SocialIconLink>
                        <SocialIconLink
                            href="https://www.instagram.com/spartansgrill/"
                            target="_blank"
                            aria-label="Redirect to Instagram"
                            rel="noopener noreferrer"
                        >
                            <FaInstagram />
                        </SocialIconLink>
                    </SocialIcons>
                </SocialItems>
            </SocialMediaWrap>

            {/* Collage Section */}
            <Collage photos={Photos} />

            {/* Allergy Information */}
            <Allergy />
        </>
    );
};

export default Main;
