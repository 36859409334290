import React from 'react'
import {MenuHead, MenuH1, MenuP1Link, SlideBackground} from './SliderData'
// import MenuSlider from '../MenuSlider/MenuSlider'
import 'bootstrap/dist/css/bootstrap.min.css';

function Slider ({slides}){

    return (
        <>
        <SlideBackground className='slider'>
            <MenuHead>
                <MenuH1>
                    Spartan's Grill Menu
                </MenuH1>
                {/* <MenuQr
                href='https://order.online/business/spartans-grill-846289'
                target='_blank'
                aria-label='Redirect to DoorDash Online Ordering'
                rel='noopener noreferrer'
                >
                    {SliderData.map((slide, index)=> {
                    return(
                        <>
                        <img className='menu-qr' key={index} src={slide.image} alt={slide.alt}/>
                        </>
                        ) 
                    })}
                </MenuQr> */}
                {/* <MenuP1
                href='https://order.online/business/spartans-grill-846289'
                // href='#'
                target='_blank'
                aria-label='Redirect to Doordash USA Online Ordering'
                rel='noopener noreferrer'
                >
                Click To View Menu
                </MenuP1> */}
                <MenuP1Link to="/coming-soon" aria-label="Redirect to Coming Soon">Click To View Menu</MenuP1Link>
            </MenuHead>
        </SlideBackground>
        {/* <MenuSlider/> */}
        </>
    ) 
}

export default React.memo(Slider)



// <>
//     <img className='menu-qr' key={index} src={slide.image} alt={slide.alt}/>
        
//         {/* className would be 'menu' */}
//     <div className={index === current ? 'slide active' : 'slide'} key={index}>
//         {/* {index === current && (
//             //***Here goes Image tag***
//         )} */}
//     </div>
// </>