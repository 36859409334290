import React from 'react';
import {
    FaFacebook,
    FaInstagram,
    FaMapMarkerAlt
} from 'react-icons/fa';
import {
    FooterContainer,
    FooterWrap,
    SocialMedia,
    SocialMediaWrap,
    SocialLogo,
    SocialP,
    SocialTag,
    SocialIcons,
    SocialIconLink
} from './FooterElement';
import Logo from '../../images/logo.png'
import { Fragment } from 'react/cjs/react.production.min';


const Footer = () => {
    return (
        <Fragment>
            <FooterContainer>
                <FooterWrap>
                    <SocialMedia>
                        <SocialMediaWrap>
                            <SocialLogo to='/'><img className='footer-img' src={Logo} alt='Spartan Soldier Logo'></img></SocialLogo>
                            <span className='span-wrap'>
                                <SocialP>123 Rockaway Ave, Valley Stream, NY 11580</SocialP>
                                <SocialP>
                                    <SocialTag classNAme='phone-a-tag 'href="tel:1-516-206-3000" aria-label="Call Phone Number (347) 233-3183">
                                    <span role="img" aria-label='Emoji'>📞</span>(516)206-3000
                                    </SocialTag>
                                </SocialP>
                                <SocialP>Copyright © 2021 Spartan's Grill</SocialP>
                            </span>
                            <SocialIcons>
                                <SocialIconLink 
                                href='https://www.facebook.com/SpartansGrill123/'
                                target='_blank'
                                aria-label='Redirect to Facebook'
                                rel='noopener noreferrer'>
                                    <FaFacebook />
                                </SocialIconLink>
                                <SocialIconLink 
                                href='https://www.instagram.com/spartansgrill/'
                                target='_blank'
                                aria-label='Redirect to Instagram'
                                rel='noopener noreferrer'>
                                    <FaInstagram />
                                </SocialIconLink> 
                                <SocialIconLink 
                                href='https://goo.gl/maps/PTjmu6NhHbHaqjFC7'
                                target='_blank'
                                aria-label='Redirect to Location'
                                rel='noopener noreferrer'>
                                    <FaMapMarkerAlt />
                                </SocialIconLink> 
                            </SocialIcons>
                        </SocialMediaWrap>
                    </SocialMedia>
                </FooterWrap>
            </FooterContainer>
            
        </Fragment>

    );
};

export default React.memo(Footer);