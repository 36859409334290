import React, { useState } from 'react';
// import Gallery from 'react-photo-gallery';
import { Photos, SlideContainer } from './Photos';
import { FaArrowAltCircleRight, FaArrowAltCircleLeft } from 'react-icons/fa';

const Collage = ({ photos }) => {
    const [current, setCurrent] = useState(0);
    const length = photos.length;

    const nextSlide = () => {
        setCurrent(current === length - 1 ? 0 : current + 1);
    };

    const prevSlide = () => {
        setCurrent(current === 0 ? length - 1 : current - 1);
    };

    if (!Array.isArray(photos) || photos.length <= 0) {
        return null;
    }

    return (
        <SlideContainer className="slide-wrap">
            <FaArrowAltCircleLeft className="left-a" onClick={prevSlide} />
            <FaArrowAltCircleRight className="right-a" onClick={nextSlide} />
            {Photos.map((slide, index) => {
                return (
                    <div
                        className={index === current ? 'slide-start' : 'slide-go'}
                        key={index}
                    >
                        {index === current && (
                            <img
                                className="collage-image"
                                src={slide.image}
                                alt={slide.alt}
                            />
                        )}
                    </div>
                );
            })}
        </SlideContainer>
    );
};

export default React.memo(Collage);
