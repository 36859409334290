import React from 'react'
import {Nav, NavLink, LogoImg, NavIcon, Bars, PhoneIcon, PhoneIconLink, Phone} from './NavbarElements'
import Logo from '../../images/logo.png'


const Navbar = ({toggle}) => {
    return (
        <Nav>
            <NavLink to='/'>
                <LogoImg src={Logo} alt='Spartan Soldier Logo'></LogoImg>
            </NavLink>
            <NavIcon onClick={toggle}>
                <Bars/>
            </NavIcon>
            <PhoneIcon>
                <PhoneIconLink 
                href="tel:1-516-206-3000"
                aria-label="Call Phone Number (347) 233-3183">
                    <Phone/>
                </PhoneIconLink>
            </PhoneIcon>
        </Nav>
    )
}

export default React.memo(Navbar)
