import styled from 'styled-components';
import HeadPic from '../../images/food-16-xs.jpg'
import woodBackground from '../../images/black-wall-1.jpeg'
import { Link } from 'react-router-dom';



export const AboutContainer = styled.div`
    height: 100%;
    background:url(${HeadPic});
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    

    @media screen and (max-width: 280px){
        background-attachment: scroll;
        height: 65vh;
        background-size: 100% 100%;
    }

    @media screen and (min-width: 320px){
        background-attachment: scroll;
        height: 65vh;
        background-size: 100% 100%;
    }

    @media screen and (min-width: 360px){
        background-attachment: scroll;
        height: 65vh;
        background-size: 100% 100%;
    }

    @media screen and (min-width: 375px){
        background-attachment: scroll;
        height: 65vh;
        background-size: 125% 100%;
    }

    @media screen and (min-width: 411px){
        background-attachment: scroll;
        height: 65vh;
        background-size: 100% 100%;
    }

    @media screen and (min-width: 540px){
        background-attachment: scroll;
        height: 70vh;
        background-size: 100% 100%;
    }

    @media screen and (min-width: 768px){
        background-attachment: scroll;
        background-size: 100% 100%;
        height: 75vh;

    }

    @media only screen and (min-width: 568px) and (orientation: landscape) {
        height: 150vh;
        background-size: 100% 100%
    }

    @media only screen and (min-width: 640px) and (orientation: landscape) {
        height: 125vh;
        background-size: 100% 100%
    }

    @media only screen and (min-width: 653px) and (orientation: landscape) {
        height: 175vh;
        background-size: 100% 100%
    }

    @media only screen and (min-width: 717px) and (orientation: landscape) {
        height: 125vh;
        background-size: 100% 100%

    }

    @media only screen and (min-width: 731px) and (orientation: landscape) {
        height: 165vh;
        background-size: 100% 100%;
    }

    @media only screen and (min-width: 823px) and (orientation: landscape) {
        height: 175vh;
        background-size: 100% 100%;
    }
    
`;

export const AboutWrap = styled.div`
    height: 75vh;
    padding: 0rem calc((100vw - 1300px) / 2);

    @media screen and (max-width: 650px) {
        height: 65vh;
    }

    @media only screen and (min-width: 568px) and (orientation: landscape) {
        height: inherit;
    }

    @media only screen and (min-width: 640px) and (orientation: landscape) {
        height: inherit;
    }

    @media only screen and (min-width: 812px) and (orientation: landscape) {
        height: inherit;
    }
`;

export const AboutItems = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 1.5rem;

    color: white;

    @media only screen and (min-width: 812px) and (orientation: landscape) {
        height: inherit;
    }
`;

export const AboutH1 = styled.h1`
    display: flex;
    flex-direction: center;
    justify-content: center;
    text-align: center;
    letter-spacing: 1px;
    font-size: clamp(1.2rem, 5vw, 2rem);
    margin-bottom: 1rem;
    color: #B99E67;
    text-transform: uppercase;
    animation-duration: 1.25s;
    animation-name: fadeInDown;
    @keyframes fadeInDown {
        0% {
            opacity: 0;
            transform: translate3d(0,-100%,0);
        }
        
        100% {
            opacity: 1;
            transform: none;
        }
    }
    
`;
export const AboutH2 = styled.h1`
    display: flex;
    flex-direction: center;
    justify-content: center;
    text-align: center;
    letter-spacing: 1px;
    font-size: clamp(2.5rem, 8vw, 5rem);
    margin-bottom: 1rem;
    text-transform: uppercase;
    animation-duration: 1.25s;
    animation-name: fadeInUp;
    @keyframes fadeInUp {
        0% {
            opacity: 0;
            transform: translate3d(0,100%,0);
        }
        
        100% {
            opacity: 1;
            transform: none;
        }
    }
`;
export const InfoContainer = styled.div`
    background:url(${woodBackground});
    background-size: 100% 100%;
    color: white;
    height: 100vh;
    display: flex;
    flex-direction: row;
    border-top: 1px solid white;
    border-bottom: 1px solid white;

    @media screen and (max-width: 280px){
        height: inherit;
    }

    @media screen and (min-width: 320px){
        height: inherit;
    }

    @media screen and (min-width: 768px){
        height: 75vh;
    }

    @media screen and (min-width: 1024px){
        height: 60vh;
    }

    @media only screen and (min-width: 568px){
        height: inherit;
    }

    
    @media only screen and (min-width: 731px) and (orientation: landscape) {
        height: inherit;
    }
    
    @media only screen and (min-width: 768px){
        height: 65vh;
    }

    @media only screen and (min-width: 1024px){
        height: 65vh;
    }
    
    @media only screen and (min-width: 812px) and (orientation: landscape) {
        height: inherit;
    }

    @media only screen and (min-width: 1024px) and (orientation: landscape) {
        height: 100vh;
    }


`;
export const InfoWrap = styled.div`
    height: 100%;
`;
export const InfoItems = styled.div`
    height: 100%;
    padding: 0.5rem 2rem; 
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    @media screen and (max-width: 280px) {
        padding: 2.5rem 0.5rem;
    }

    @media screen and (min-width: 320px) {
        padding: 2.5rem 0.5rem;
    }

    @media screen and (min-width: 1024px) {
        padding: 2.5rem 15rem;
    }
`;
export const InfoH1 = styled.h1`
    font-size: clamp(1.5rem, 3vw, 2.5rem);
    padding: 0.5rem 2rem;
    text-transform: uppercase;
    margin-bottom: 1rem;

`;
export const InfoP1 = styled.p`
    font-family: 'Poppins',sans-serif;
    font-size: clamp(1rem, 2.5vw, 1.2rem);
    word-wrap: break-word;
    line-height: 1.2;
    padding: 0.5rem 2rem;
    letter-spacing: 1px;    
    font-weight: 200;

    @media screen and (max-width: 280px) {
        padding: 0.5rem 0.5rem;
    }
`;
export const InfoP2 = styled.p`
    font-family: 'Poppins',sans-serif;
    font-size: clamp(1rem, 2.5vw, 1.2rem);
    word-wrap: break-word;
    line-height: 1.2;
    padding: 0.5rem 2rem;
    letter-spacing: 1px;  
    font-weight: 200; 

    @media screen and (max-width: 280px) {
        padding: 0.5rem 0.5rem;
    }
    
`;
export const InfoP3 = styled.p`
    font-family: 'Poppins',sans-serif;
    font-size: clamp(1rem, 2.5vw, 1.2rem);
    word-wrap: break-word;
    line-height: 1.2;
    padding: 0.5rem 2rem;
    letter-spacing: 1px; 
    font-weight: 200;

    @media screen and (max-width: 280px) {
        padding: 0.5rem 0.5rem;
    }
    
`;
export const InfoBtnWrap = styled.div`
font-size: clamp(1rem, 3vw, 1.2rem);
margin-top: 1rem;
letter-spacing: 0.5px;

padding: 0.25rem 0.75rem;
border: none;
background: #e31837;
transition: 0.2s ease-out;
&:hover {
    background: white;
    transition: 0.2s ease-out;
    cursor: pointer;
    color: black;
}
`;

export const InfoBtnWrapLink = styled(Link)`
font-size: clamp(1rem, 3vw, 1.2rem);
margin-top: 1rem;
letter-spacing: 0.5px;

padding: 0.25rem 0.75rem;
border: none;
background: #e31837;
color: #ffff;
transition: 0.2s ease-out;
text-transform: none;
text-decoration: none;

&:hover {
    background: white;
    transition: 0.2s ease-out;
    cursor: pointer;
    color: black;
}
`;


