import React from 'react'
import {LocationContainer, LocationWrap} from './LocationElements'

const Location = () => {
    return (
        <LocationContainer>
            <LocationWrap>
                <iframe className='map' title='maps' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2108.1099940095423!2d-73.7000546888867!3d40.66279676575304!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c264884cf2ddcb%3A0x38763c8f5e907286!2s123%20Rockaway%20Ave%2C%20Valley%20Stream%2C%20NY%2011580!5e0!3m2!1sen!2sus!4v1621237982673!5m2!1sen!2sus" width='550' height='350' frameBorder='0' style={{border:1}} allowFullScreen='' aria-hidden="false" tabIndex="0"></iframe>            
            </LocationWrap>
        </LocationContainer>
    )
}

export default Location
