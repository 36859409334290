import styled from 'styled-components';
import PicOne from '../../images/food-5.jpg';
// import PicTwo from '../../images/food-10.jpg';
import woodBackground from '../../images/black-wall-1.jpeg'

// import backPic from '../../images/food-8.jpg'



export const PhotoData = [

    {
        image: PicOne,
        alt: 'pic-one'
    }
    
]

// linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.1)), url(${backPic})
export const  AboutContainer = styled.div`

    background:url(${woodBackground});
    background-size: 100% 100%;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    padding: 0.5rem 1.5rem;
    display: flex;
    flex-direction: row;
    width: 100%;  
    height: 100%;

    @media screen and (max-width: 823px) {
        flex-direction: column;  
        background-attachment: scroll;
        background-position-x: 50%;
        background-position-y: 0%;
        background-position: center top;
        background-repeat: no-repeat;
        background-size: 100% 100%;

        padding: 2.5rem 1.5rem;
    }

    @media screen and (max-width: 320px) {
        padding: 2.5rem 0.5rem;
    }

    @media only screen and (min-width: 280px) and (max-width: 653px)  {
        background-size: 100% 100%;

    }

    @media screen and (min-width: 1024px) {
        display: grid;
    }

    @media screen and (min-width: 1366px){
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

`;

export const AboutText = styled.div`

    width: auto; 
    height: 100vh;

    @media screen and (max-width: 320px) {
        width: auto;
        height: inherit;
    }

    @media only screen and (min-width: 375px) and (max-width: 812px) {
        height: 100%;
    }

    @media screen and (min-width: 1024px) {
        height: inherit;
        padding: 1.5rem 1.5rem;
    }

    @media only screen and (min-width: 568px) and (orientation: landscape){
        width: inherit;
        height: inherit;
    }

    @media only screen and (min-width: 1366px) and (orientation: landscape){
        width: inherit;
        height: auto;
    }
`; 

export const AboutWrap = styled.div`
    width: 100%; 
    height: 100%;
    padding: 0.5rem 2rem; 
    display: flex;
    color: white;
    flex-direction: column;
    justify-content: center;
    align-items:  center;
    text-align: center;

    @media screen and (max-width: 812px) {
        padding: 1.5rem 0.5rem;
    }

    @media only screen and (min-width: 1366px) and (orientation: landscape){
        height: inherit;
    }
    
`;

export const AboutH1 = styled.h1`
    width: 100%;
    font-size: 35px;
    padding: 0.5rem 2rem;
    text-transform: uppercase;
    
`;

export const AboutP = styled.p`
    width: 100%;
    font-size: 18px;
    font-family: 'Poppins',sans-serif;
    word-wrap: break-word;
    line-height: 2;
    padding: 1.5rem 2rem;
    font-weight: 200;


    @media screen and (max-width: 812px) {
        line-height: 1.5;
    }
    @media screen and (max-width: 280px) {
        padding: 1.5rem 0.5rem;
    }
`;


export const ButtonWrap = styled.div`
    width: 100%;
    display: flex;
    padding: 1.5rem 0.5rem;
    justify-content: space-evenly;
    align-items: center;

    @media screen and (min-width: 1024px){
        height: inherit;
        width: 65%;
        padding: 1.5rem 1.5rem;
    }

`;

export const AboutButton = styled.button`
    
    font-size: clamp(1rem, 3vw, 1.2rem);
    padding: 0.5rem 1rem;
    letter-spacing: 0.5px;
    border: none;
    background: #e31837;
    transition: 0.2s ease-out;
    text-decoration: none;
    color: white;
    &:hover {
        background: white;
        transition: 0.2s ease-out;
        cursor: pointer;
        color: black;
    }       

    @media screen and (max-width: 280px) {
        padding: 0.5rem 0.5rem;
    }
`;

