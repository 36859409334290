import styled from 'styled-components';
import woodBackground from '../../images/black-wall-1.jpeg';
import qrCode from '../../images/doordash-qr.jpg';
import { Link } from 'react-router-dom';



export const SliderData = [

    {
        image: qrCode,
        alt: 'menu-qr-code'
    }
]

export const SlideBackground = styled.div`
    background: url(${woodBackground});
    // background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    
    @media only screen and (min-width: 360px) and (max-width: 640px) {
        background-size: 100% 100%;
    }

    @media only screen and (min-width: 640px) and (orientation: landscape) {
        background-size: 100% 100%;
    }
    
    @media only screen and (min-width: 1024px) and (orientation: landscape) {
        height: 125vh;
        background-size: 100% 100%;
    }
`
export const MenuHead = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #fff;
    text-align: center;
    padding: 0 1rem;
`
export const MenuH1 = styled.h1`
    font-size: clamp(1.25rem, 4vw, 3.5rem);
    margin-bottom: 1rem;
    letter-spacing: 1px;
    display: flex;
    flex-direction: center;
    justify-content: center;
    text-align: center;
`
export const MenuQr = styled.a`
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
`
export const MenuP1 = styled.a`
    display: flex;
    flex-direction: center;
    justify-content: center;
    text-align: center;
    padding: 1rem 1rem;
    font-family: 'Poppins',sans-serif;
    font-size: clamp(0.75rem,3vw,1.5rem);
    font-style: italic;
    text-decoration: none;
    color: white;
    &:hover{
        color: #e31837;
    }
`
export const MenuP1Link = styled(Link)`
    display: flex;
    flex-direction: center;
    justify-content: center;
    text-align: center;
    padding: 1rem 1rem;
    font-family: 'Poppins',sans-serif;
    font-size: clamp(0.75rem,3vw,1.5rem);
    font-style: italic;
    text-decoration: none;
    color: white;
    
    &:hover{
        color: #e31837;
    }
`;