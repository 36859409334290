import React from 'react'
import { withRouter, Route, Switch } from 'react-router-dom';
import Main from '../components/Main';
import AboutPage from '../components/AboutPage';
import Feature from '../components/Feature';
import ComingSoon from '../components/ComingSoon'; // Import the ComingSoon component


class Routes extends React.Component {
    render(){
    return (
        <Switch>
            
            <Route path="/menu" component={Feature} />
            <Route path="/about" component={AboutPage} />
            <Route path="/coming-soon" component={ComingSoon} /> 
            <Route path="/" component={Main} />
        </Switch>
        )
    }
}
export default withRouter(Routes)