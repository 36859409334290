import React from "react";
import styled from "styled-components";

const ComingSoonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #ec2c38; /* Updated to match the logo */
  color: white;

  @media (min-width: 768px) {
    flex-direction: row; /* Side-by-side for desktop */
    padding: 20px;
  }
`;

const VideoContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
    border-radius: 10px;

  overflow: hidden; /* Prevents bleeding */
  max-height: 100%; /* Keep video within bounds */
  margin-top: 20px; /* Add spacing above the video on mobile */

  video {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Maintain aspect ratio */
    border-radius: 10px;
  }

  @media (min-width: 768px) {
    margin-top: 0;
    max-width: 60%; /* Limit video width on desktop */
  }
`;

const TextContainer = styled.div`
  flex: 1;
  display: flex; /* Use flexbox for alignment */
  flex-direction: column; /* Stack elements vertically */
  justify-content: center; /* Center vertically */
  align-items: center; /* Center horizontally */
  text-align: center;
  padding: 20px;

  h1 {
    font-size: 2rem;
    margin-bottom: 1rem;
  }

  p {
    font-size: 1.2rem;
    margin-bottom: 1rem;
    line-height: 1.5; /* Improves readability */
  }

  a {
    display: block;
    margin: 0.5rem 0;
    color: #ffcc00; /* Bright yellow for links */
    font-weight: bold;
    text-decoration: none;
  }

  @media (min-width: 768px) {
    text-align: left;
    padding: 30px; /* Adds padding for desktop */
    align-items: flex-start; /* Align left for desktop */
  }
`;


const ComingSoon = () => {
  return (
    <ComingSoonContainer>
      <VideoContainer>
        <video src="/SpartansGrill.webm" autoPlay loop playsInline />
      </VideoContainer>
      <TextContainer>
        <h1>We're Perfecting Delivery!</h1>
        <p>
        Great news! While we’re upgrading our delivery system to serve you better, you can still enjoy your favorite meals through our trusted partners:
        </p>
        <a href="https://www.ubereats.com/store/spartans-grill/HGakabQcRpqM_eqKWyKqSA" target="_blank" rel="noopener noreferrer">
          Uber Eats
        </a>
        <a href="https://www.doordash.com/store/spartans-grill-valley-stream-1805713" target="_blank" rel="noopener noreferrer">
          DoorDash
        </a>
      </TextContainer>
    </ComingSoonContainer>
  );
};

export default ComingSoon;
