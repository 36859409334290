import React from 'react'
import { Fragment } from 'react/cjs/react.production.min'
import {AllergyContainer, AllergyWrap, AllergyItems, AllergyH1, AllergyP, AllergyBtnLink, AllergyP1} from './AllergyPage'
// import {MainBtnLink} from '../Main/MainElements'

const Allergy = () => {
    return (
        <Fragment>
            <AllergyContainer>
                <AllergyWrap>
                    <AllergyItems>
                        <AllergyH1>FOOD ALLERGY NOTICE:</AllergyH1>
                        <AllergyP>Food Prepared In This Establishment May Contain Wheat, Fish, Dairy, Eggs, Soy And Nuts</AllergyP>
                        <AllergyP1>Product Images Are For Illustrative Purposes Only And May Differ From The Actual Product</AllergyP1>
                        <AllergyBtnLink
                            to="/coming-soon" aria-label="Redirect to Coming Soon">
                                Order Now
                        </AllergyBtnLink>
                        {/* <MainBtnLink to="/coming-soon" aria-label="Redirect to Coming Soon">
                            Order Now
                        </MainBtnLink> */}
                    </AllergyItems>
                </AllergyWrap>
            </AllergyContainer> 
        </Fragment>
    )
}

export default Allergy
